import { getVoiceApiResponeProps } from "@/services";
import { create } from "zustand";

// 创建生成任务
interface CreatePageProp {
  title: string;
  text: string;
}

// 创建可选任务
interface CreateSelectPageProp {
  title: string;
  text: string;
  videoData: {
    url: string;
    distance: number;
  }[];
}

// 创建可选任务的等待生成任务
interface CreateSelectPageDataAwaitProp {
  videoDataAwait: { text: string; video: string }[];
}

// ppt
interface CreatePPTPageProp {
  title: string;
  text: string;
  selectKey: string;
  uploadFile: any[];
  imageList: {
    image: {
      url: string;
      logo: {
        image_id: number;
        ratio: number;
        position: {
          weight: number;
          height: number;
        };
      };
      copy_right: {
        copy_right_id: number;
        font_size: number;
        position: {
          weight: number;
          height: number;
        };
      };
    };
    text?: string;
    flag?: boolean;
  }[];
}

//翻译
interface VideoTranslateProp {
  name: string;
  currentFile: { name: string; url: string }[];
  dubbingVideoUrl: string;
  srt_file: string; //字幕文件url
  uploadFile: any[];
  voice: number;
  voiceList: (string | number)[];
  speech_rate: number;
  model: string[];
  taskMsg: {
    task_id?: string;
    status?: number;
  };
  result_subtitle: {
    new: {
      text: string;
      start: string;
      end: string;
    };
    old: {
      text: string;
      start: string;
      end: string;
    };
  }[];
}

//视频配音
interface VideoDubbingAudioProp {
  audioText?: string;
  voiceList: (string | number)[];
  name: string;
  voice: number;
  speech_rate: number;
  uploadFile: any[];
  srt_file: string; //字幕文件url
  taskMsg: {
    task_id?: string;
    status?: number;
  };
  source: {
    text: string;
    start: string;
    end: string;
  }[];
}

//视频参数设置
export interface SettingStyleDataProps {
  createTask: {
    text_video_speaking_speed: number;
    text_video_subtitle: number[];
    text_video_bgc_music: number;
    text_video_voice: number[];
  };
  createSelectTask: {
    text_select_video_speaking_speed: number;
    text_select_video_subtitle: number[];
    text_select_video_bgc_music: number;
    text_select_video_voice: number[];
  };
  dubbingTask: { dubbing_speaking_speed: number; dubbing_voice: number[] };
  createPPTTask: {
    ppt_speaking_speed: number;
    ppt_subtitle: number[];
    ppt_bgc_music: number;
    ppt_voice: number[];
  };
  translateTask: {
    translate_speaking_speed: number;
    translate_voice: number[];
    translate_model: number;
    translate_term: string;
    translate_subtitle: number[];
  };
}

interface stateGlobal {
  voice: getVoiceApiResponeProps[];
  createPageList: CreatePageProp;
  createSelectPageList: CreateSelectPageProp;
  createSelectPageDataAwaitList: CreateSelectPageDataAwaitProp;
  createPPTPageList: CreatePPTPageProp;
  videoTranslateData: VideoTranslateProp;
  videoDubbingAudioData: VideoDubbingAudioProp;
  settingStyleData: SettingStyleDataProps;
  setVoice: (newVoice: getVoiceApiResponeProps[]) => void;
  setCreatePageList: (newCreatePageList: CreatePageProp) => void;
  setCreateSelectPageList: (
    newCreateSelectPageList: CreateSelectPageProp
  ) => void;
  setCreateSelectPageDataAwaitList: (
    newCreateSelectPageDataAwaitList: CreateSelectPageDataAwaitProp
  ) => void;
  setCreatePPTPageList: (newCreatePPTPageList: CreatePPTPageProp) => void;
  setVideoTranslateData: (newVideoTranslateData: VideoTranslateProp) => void;
  setVideoDubbingAudioData: (
    newVideoDubbingAudioData: VideoDubbingAudioProp
  ) => void;
  setSettingStyleData: (newValue: SettingStyleDataProps) => void;
}

export const useVideoStore = create<stateGlobal>((set) => ({
  voice: [],
  createPageList: { title: "", text: "" },
  createSelectPageList: { title: "", text: "", videoData: [] },
  createSelectPageDataAwaitList: { videoDataAwait: [] },
  createPPTPageList: {
    title: "",
    text: "",
    selectKey: "",
    uploadFile: [],
    imageList: [],
  },
  videoTranslateData: {
    name: "",
    currentFile: [],
    dubbingVideoUrl: "",
    srt_file: "",
    uploadFile: [],
    voice: 110,
    voiceList: [],
    speech_rate: 0,
    model: [],
    taskMsg: {},
    result_subtitle: [
      {
        new: {
          text: "",
          start: "00:00:00",
          end: "00:00:01",
        },
        old: {
          text: "",
          start: "00:00:00",
          end: "00:00:01",
        },
      },
    ],
  },
  videoDubbingAudioData: {
    name: "",
    audioText: "",
    voice: 11,
    voiceList: [],
    speech_rate: 0,
    uploadFile: [],
    srt_file: "",
    taskMsg: {},
    source: [
      {
        text: "",
        start: "00:00:00",
        end: "00:00:01",
        state: true,
      },
    ],
  },
  settingStyleData: {
    createTask: {
      text_video_speaking_speed: 0,
      text_video_subtitle: [0],
      text_video_bgc_music: -1,
      text_video_voice: [1, 110],
    },
    createSelectTask: {
      text_select_video_speaking_speed: 0,
      text_select_video_subtitle: [0],
      text_select_video_bgc_music: -1,
      text_select_video_voice: [1, 110],
    },
    dubbingTask: { dubbing_speaking_speed: 0, dubbing_voice: [1, 110] },
    createPPTTask: {
      ppt_speaking_speed: 0,
      ppt_subtitle: [0],
      ppt_bgc_music: -1,
      ppt_voice: [1, 110],
    },
    translateTask: {
      translate_speaking_speed: 0,
      translate_voice: [1, 110],
      translate_model: 0,
      translate_term: "null",
      translate_subtitle: [0],
    },
  },

  setVoice(newVoice: getVoiceApiResponeProps[]) {
    !!newVoice &&
      set(() => ({
        voice: newVoice,
      }));
  },
  setCreatePageList(newCreatePageList: CreatePageProp) {
    !!newCreatePageList &&
      set(() => ({
        createPageList: newCreatePageList,
      }));
  },
  setCreateSelectPageList(newCreateSelectPageList: CreateSelectPageProp) {
    !!newCreateSelectPageList &&
      set(() => ({
        createSelectPageList: newCreateSelectPageList,
      }));
  },
  setCreateSelectPageDataAwaitList(
    newCreateSelectPageDataAwaitList: CreateSelectPageDataAwaitProp
  ) {
    !!newCreateSelectPageDataAwaitList &&
      set(() => ({
        createSelectPageDataAwaitList: newCreateSelectPageDataAwaitList,
      }));
  },
  setCreatePPTPageList(newCreatePPTPageList: CreatePPTPageProp) {
    !!newCreatePPTPageList &&
      set(() => ({
        createPPTPageList: newCreatePPTPageList,
      }));
  },
  setVideoTranslateData(newVideoTranslateData: VideoTranslateProp) {
    !!newVideoTranslateData &&
      set(() => ({
        videoTranslateData: newVideoTranslateData,
      }));
  },
  setVideoDubbingAudioData(newVideoDubbingAudioData: VideoDubbingAudioProp) {
    !!newVideoDubbingAudioData &&
      set(() => ({
        videoDubbingAudioData: newVideoDubbingAudioData,
      }));
  },
  setSettingStyleData(newValue: SettingStyleDataProps) {
    !!newValue &&
      set(() => ({
        settingStyleData: newValue,
      }));
  },
}));
