import { useEffect, useState } from "react";
import { message } from "antd";
import { useRequest, useUnmount } from "ahooks";
import { useNavigate } from "react-router-dom";
import {
  postDownLoadSubtitleApi,
  postTranslateVideoGenerateApi,
  updateDubbingDataApi,
} from "@/services";
import { useAigcVideoStore, useVideoStore } from "@/store";
import { subtitleLen, textReplace } from "@/components";

import { TranslateVideoListProp, subtitleProp } from "./props";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";
import { TranslateDubbingProps } from "@/components/VideoRate/props";
import { downFile } from "@/utils/util";

const useAction = () => {
  // 存储视频
  // 本地存储视频
  const [myTheme, setTranslateVideoList] = useAigcVideoStore((state) => [
    state.myTheme,
    state.setTranslateVideoList,
  ]);

  // 字幕
  const [videoTranslateData, settingStyleData, setVideoTranslateData] =
    useVideoStore((state) => [
      state.videoTranslateData,
      state.settingStyleData,
      state.setVideoTranslateData,
    ]);

  // 存储视频进度列表
  const [videoTranslateTask, setVideoTranslateTask] =
    useVideoGenerateStateStore((state) => [
      state.videoTranslateTask,
      state.setVideoTranslateTask,
    ]);

  const [tempVideoData] = useVideoStore((state) => [state.videoTranslateData]);

  const [subtitleList, setSubtitleList] = useState<TranslateVideoListProp[]>(
    tempVideoData.result_subtitle.map((item, index) => {
      return {
        ...item,
        id: index,
        state: true,
      };
    })
  );

  const [subtitle, subtitleAction] = useState<subtitleProp>({
    main: settingStyleData.translateTask.translate_subtitle[0],
    second: settingStyleData.translateTask.translate_subtitle[1],
  });
  const [selectValue, selectValueAction] = useState<number[]>(
    settingStyleData.translateTask.translate_subtitle
  );
  const [loadingGenerate, loadingGenerateAction] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState<number>(1);

  const navigate = useNavigate();

  const { run: updateDubbingData, loading: updateDubbingDataLoading } =
    useRequest(updateDubbingDataApi, {
      manual: true,
      onSuccess() {
        message.success("保存成功");
      },
    });

  const { loading: getSubtitleFileSrcLoading, run: getSubtitleFileSrc } =
    useRequest(postDownLoadSubtitleApi, {
      manual: true,
      onSuccess(result) {
        downFile(result.data.result);
      },
    });

  const tanslateSubtitleChange = (value: number[]) => {
    selectValueAction(value);

    if (value[0] === 0) {
      subtitleAction({ main: value[0], second: 0 });
    } else if (value[0] >= subtitleLen) {
      selectValueAction(() => []);
      subtitleAction({
        main: undefined,
        second: undefined,
      });
    } else {
      subtitleAction({
        main: value[0],
        second: value[1] ? value[1] : undefined,
      });
    }
  };

  const audioListChange = (newAudioList: TranslateVideoListProp[]) => {
    setSubtitleList(newAudioList);
  };

  // 检查配置项
  const generateCheck = () => {
    const timeflag = subtitleList.every((item) => item.state);
    if (timeflag && typeof subtitle.main !== "undefined") {
      return true;
    }
    return false;
  };

  // 生成视频
  const generateVideo = () => {
    loadingGenerateAction(true);

    const params = {
      voice: tempVideoData.voice,
      speech_rate: tempVideoData.speech_rate,
      video: tempVideoData.dubbingVideoUrl,
      subtitles: {
        main: subtitle.main !== undefined ? subtitle.main : 0,
        second:
          subtitle.second !== undefined && subtitle.second !== 0
            ? subtitle.second - subtitleLen
            : 0,
      },
      source: subtitleList.map((item) => {
        return {
          new: { ...item.new, text: textReplace(item.new.text) },
          old: item.old,
        };
      }),
    };

    generateVideoRequest.run(params);
  };

  const generateVideoRequest = useRequest(postTranslateVideoGenerateApi, {
    manual: true,
    onSuccess(result) {
      loadingGenerateAction(false);
      message.success("成功生成");
      setVideoTranslateTask({
        ...videoTranslateTask,
        video: {
          ...videoTranslateTask.video,
          tasks: [
            ...videoTranslateTask.video.tasks,
            {
              id: result.data?.task_id,
              name: videoTranslateData.name,
              isGenerate: false,
              state: 0,
            },
          ],
        },
      });
    },
    onError(e) {
      loadingGenerateAction(false);
      message.error("生成失败");
    },
  });

  const getDubbingData = (
    name: string,
    data: TranslateDubbingProps,
    voiceList: (string | number)[],
    videoUrl: string,
    model: string[],
    task_id: string,
    status: number
  ) => {
    setVideoTranslateData({
      ...videoTranslateData,
      name,
      voiceList,
      dubbingVideoUrl: videoUrl,
      voice: data.voice,
      speech_rate: data.speech_rate,
      result_subtitle: data.subtitles,
      srt_file: data.srt_file,
      model: model,
      taskMsg: {
        task_id: task_id,
        status: status,
      },
    });

    navigate("/videoTranslate/createSelectTask/adjustSubtitle");
  };

  const getVideoData = (url: string, name: string) => {
    setTranslateVideoList(url, name);
  };

  const updateCurrentDubbingData = () => {
    updateDubbingData({
      task_id: tempVideoData.taskMsg?.task_id ?? "",
      status: tempVideoData.taskMsg?.status ?? 2,
      result: {
        speech_rate: tempVideoData.speech_rate,
        srt_file: tempVideoData.srt_file,
        voice: tempVideoData.voice,
        subtitles: subtitleList.map((item) => {
          return {
            new: { ...item.new, text: textReplace(item.new.text) },
            old: item.old,
          };
        }),
      },
    });
  };

  useUnmount(() => {
    setVideoTranslateData({
      ...videoTranslateData,
      result_subtitle: subtitleList.map((item) => ({
        old: item.old,
        new: item.new,
      })),
    });
  });

  useEffect(() => {
    const newAudioList = JSON.parse(
      JSON.stringify(
        tempVideoData.result_subtitle.map((item, index) => {
          return {
            ...item,
            id: index,
            state: true,
          };
        })
      )
    );
    setSubtitleList(newAudioList);
  }, [tempVideoData]);

  useEffect(() => {
    subtitleAction({
      main: settingStyleData.translateTask.translate_subtitle[0],
      second: settingStyleData.translateTask.translate_subtitle[1],
    });
    selectValueAction(settingStyleData.translateTask.translate_subtitle);
  }, [settingStyleData.translateTask]);

  return {
    myTheme,
    loadingGenerate,
    subtitleList,
    selectValue,
    subtitle,
    videoTranslateData,
    open,
    updateDubbingDataLoading,
    selectLanguage,
    getSubtitleFileSrcLoading,
    setOpen,
    generateVideo,
    audioListChange,
    tanslateSubtitleChange,
    generateCheck,
    getVideoData,
    getDubbingData,
    updateCurrentDubbingData,
    setSelectLanguage,
    getSubtitleFileSrc,
  };
};

export default useAction;
