import { useThemeStyles } from "@/components";
import { Env } from "@/config/env";
import { message, Modal, UploadProps } from "antd";
import { FC } from "react";
import styles from "./index.module.scss";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

interface UploadTextProps {
  open: boolean;
  onOk: (value: { [key: number]: string }) => void;
  onClose: () => void;
}

const UploadText: FC<UploadTextProps> = ({ open, onOk, onClose }) => {
  const { myTheme } = useThemeStyles();

  const props: UploadProps = {
    name: "upload_file",
    multiple: true,
    // 接口
    action: `${Env.url}/api/ppt/draft`,
    accept: ".doc, .docx",
    headers: {
      "X-API-KEY": Env.apiKey,
      Accept: "application/json",
    },
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        onOk(info.file.response.result);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Modal
        title={<>上传文本</>}
        destroyOnClose
        open={open}
        onCancel={onClose}
        footer={null}
      >
        <div className={styles["upload"]}>
          <Dragger {...props} className={styles["file"]}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined
                className={`${styles["svg"]} ${
                  !myTheme && styles["text-color-night"]
                }`}
              />
            </p>
            <p>上传WORD(.doc/.docx)</p>
            <p className={styles["upload-hint"]}>点击或直接拖放文件</p>
          </Dragger>
        </div>
        <br />
        <div>
          <div>文本格式：</div>
          <div style={{ paddingLeft: 70 }}>
            <div>#p图片序号</div>
            <div>内容xxxxxxxx</div>
            <br />
            <div>举例：</div>
            <div>#p1</div>
            <div>对应第一张图片</div>
            <br />
            <div>#p2</div>
            <div>对应第二张图片</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadText;
