import type { UploadProps } from "antd";
import { Button, Input, message, Upload, Popover, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
  ButtonSelf,
  LanguageSelect,
  CascaderLanguage,
  SelectSpeakingSpeed,
  SelectSubtitle,
  useThemeStyles,
  InputTextMark,
  ButtonNormal,
  ButtonEnSpellRead,
  ButtonNumContinuouRead,
  ButtonNumSpellRead,
  ButtonNumSpellRead100,
  CardSelf,
  InputText,
} from "@/components";
import { Env } from "@/config/env";
import { BGM } from "@/lib/constants";
import ImageCard from "@/components/ImageCard";
import styles from "./index.module.scss";
import useAction from "./hooks";
import VideoRate from "@/components/VideoRate";
import SiderButton from "@/components/SiderButton";
import MaterialModal from "./MaterialModal";
import { initialMaterialData } from "@/utils/ppt-video/materialData";
import { ImageList } from "./props";
import UploadText from "./UploadText";
import { useState } from "react";

const { Dragger } = Upload;

const CreateImageTextVideo = () => {
  const { myTheme } = useThemeStyles();
  const [uploadTextOpen, setUploadTextOpen] = useState(false);

  const {
    inputRef,
    textSelected,
    inputText,
    imageList,
    loading,
    videoName,
    selectKey,
    uploadFile,
    subtitleSelectValue,
    subtitle,
    textPassFlag,
    textMarkShow,
    voiceNum,
    tasksOpen,
    materialOpen,
    languageNum,
    bgmNum,
    speechRate,
    selectBorderValue,
    textSelectedAction,
    onChangeBgm,
    selectKeyAction,
    inputTextAction,
    videoNameAction,
    onChangeText,
    setImageList,
    onChange,
    generateVideo,
    pptDel,
    pptClick,
    imageTextSubtitleChange,
    onChangeSpeakingSpeed,
    uploadFileAction,
    generateCheck,
    setTasksOpen,
    generateVideoTask,
    setMaterialOpen,
    setSelectBorderValue,
    getUploadText,
  } = useAction();

  const props: UploadProps = {
    name: "upload_file",
    multiple: true,
    // 接口
    action: `${Env.url}/api/file/convert`,
    accept: ".ppt, .pptx",
    headers: {
      "X-API-KEY": Env.apiKey,
      Accept: "application/json",
    },
    maxCount: 1,
    defaultFileList: uploadFile,
    beforeUpload(file) {
      const file_name = file.name;
      const new_file = new File([file], file_name);
      return Promise.resolve(new_file);
    },
    onChange(info) {
      const { status } = info.file;
      info.fileList = [];
      // 重新上传先清空原数组，避开useState的渲染优化。
      setImageList([]);
      selectKeyAction("");
      inputTextAction("");
      uploadFileAction(() => []);
      if (status !== "uploading") {
        uploadFileAction(() => []);
      }
      if (status === "done" && Array.isArray(info.file.response.result)) {
        message.success(`${info.file.name} file uploaded successfully.`);
        setImageList(
          info.file.response.result?.map((item: { url: string }) => {
            return {
              image: { ...initialMaterialData, url: item.url },
              flag: true,
            };
          })
        );

        videoNameAction(
          `${info.file.name.slice(0, info.file.name.lastIndexOf("."))}`
        );

        // 清空数组，保存状态
        uploadFileAction(() => [info.file]);
      } else if (status === "error") {
        // 清空数组，保存状态
        uploadFileAction(() => [info.file]);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // 点击删除清空数组
      setImageList([]);
      selectKeyAction("");
      inputTextAction("");
      // 清空状态数组
      uploadFileAction(() => []);
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <div className={styles.ro}>
        <CardSelf
          className={styles.root}
          title={
            <>
              <div className={styles["upload"]}>
                <Dragger {...props} className={styles["file"]}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined
                      className={`${styles["svg"]} ${
                        !myTheme && styles["text-color-night"]
                      }`}
                    />
                  </p>
                  <p>上传PPT(.ppt/.pptx)</p>
                  <p className={styles["upload-hint"]}>点击或直接拖放文件</p>
                </Dragger>
              </div>
              <div className={styles["image-container"]}>
                {imageList.map((item, index) => (
                  <div
                    className={styles["image-item-container"]}
                    key={item.image.url}
                  >
                    <div
                      className={`${styles["image-item"]} ${
                        selectKey === item.image.url
                          ? styles["image-item-checked"]
                          : ""
                      }`}
                      onClick={(e) =>
                        pptClick(e, item.text, item.image.url, item.flag)
                      }
                    >
                      <ImageCard
                        src={item.image.url}
                        onExpand={() => {}}
                        onDelete={pptDel(item.image.url)}
                      />
                    </div>
                    <div
                      key={index}
                      className={`${styles["image-number"]} ${
                        !myTheme && styles["text-color-night"]
                      } ${!item.flag && styles["image-number-err"]}`}
                    >
                      {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            </>
          }
        >
          <div className={styles["footer"]}>
            <div className={styles["btnAll"]}>
              {/* 边框 */}
              <Select
                value={selectBorderValue}
                onChange={(value) => setSelectBorderValue(value)}
                style={{ width: 120 }}
                options={[
                  {
                    value: 0,
                    label: "不添加边框",
                  },
                  {
                    value: 1,
                    label: "添加边框",
                  },
                ]}
              />
              {/* 倍速 */}
              <SelectSpeakingSpeed
                value={speechRate}
                onChange={onChangeSpeakingSpeed}
              />
              {/* 字幕 */}
              <SelectSubtitle
                onChange={imageTextSubtitleChange}
                value={subtitleSelectValue}
                subtitle={subtitle}
              />
              <LanguageSelect
                value={bgmNum}
                onChange={onChangeBgm}
                data={BGM}
                placeholder="背景音乐"
              />
              {/* 人声 */}
              <CascaderLanguage
                value={languageNum}
                onChange={onChange}
                disabled={true}
              />
              <ButtonSelf flag={true} onClick={() => setMaterialOpen(true)}>
                添加素材
              </ButtonSelf>
              <ButtonSelf
                flag={true}
                onClick={() => {
                  if (imageList.length === 0) {
                    message.warning("请先上传ppt");
                    return;
                  }
                  setUploadTextOpen(true);
                }}
              >
                上传文本
              </ButtonSelf>

              <ButtonSelf
                loading={loading}
                btnName="生成"
                onClick={generateVideo}
                flag={generateCheck()}
              />
            </div>
          </div>
          {loading && (
            <div className={styles["generateTip"]}>
              <p>完成后会自动跳转，请勿离开！ </p>
            </div>
          )}
        </CardSelf>
        {/* 文本框 */}
        <CardSelf
          className={styles["headerInputContent"]}
          title={
            <Input
              className={styles["videoName"]}
              value={videoName}
              bordered={false}
              placeholder="请输入视频名称"
              onChange={({ target }) => videoNameAction(target.value)}
            />
          }
        >
          <InputText
            value={inputText}
            style={{ minHeight: "168px" }}
            onChange={(value) => onChangeText(value)}
          />
          {/* <InputTextMark
            ref={inputRef}
            height={84}
            placeholder="输入文本（建议一句话不超过200个字）"
            value={inputText}
            onChange={(value, text) => {
              onChangeText(value, text);
              formatText(text);
            }}
            onChangeSelection={(selcet) => {
              textSelectedAction(() => selcet);
            }}
          /> */}
          <div className={styles["footer"]}>
            {!textPassFlag && (
              <div className={styles["textWronTip"]}>
                字数超出，请根据需求换行！
              </div>
            )}

            {textMarkShow && (
              <Popover
                title={null}
                trigger="click"
                placement="bottom"
                content={() => (
                  <div style={{ display: "flex", gap: 10 }}>
                    <ButtonNormal position={textSelected} refs={inputRef} />
                    <ButtonEnSpellRead
                      position={textSelected}
                      refs={inputRef}
                    />

                    {voiceNum !== 100 && (
                      <ButtonNumSpellRead
                        position={textSelected}
                        refs={inputRef}
                      />
                    )}
                    {voiceNum === 100 && (
                      <ButtonNumSpellRead100
                        position={textSelected}
                        refs={inputRef}
                      />
                    )}
                    <ButtonNumContinuouRead
                      position={textSelected}
                      refs={inputRef}
                      disabled={voiceNum === 100}
                    />
                  </div>
                )}
              >
                <Button>文本标注</Button>
              </Popover>
            )}
          </div>
        </CardSelf>
        <p
          className={`${styles["foot-tip"]} ${
            myTheme ? styles["foot-tip-light"] : styles["foot-tip-night"]
          }`}
        >
          提示：
        </p>
        <div style={{ marginLeft: 40 }}>
          <p
            className={`${styles["foot-tip"]} ${
              myTheme ? styles["foot-tip-light"] : styles["foot-tip-night"]
            }`}
          >
            请输入内容，当遇到标点符号,，?？。时，将自动换行。
          </p>
          <p
            className={`${styles["foot-tip"]} ${
              myTheme ? styles["foot-tip-light"] : styles["foot-tip-night"]
            }`}
          >
            每行文本会对应一行字幕，请通过修改标点符号改变字幕出现的视频位置
          </p>
        </div>
      </div>
      <SiderButton onClick={() => setTasksOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={tasksOpen}
        onClose={() => setTasksOpen(false)}
        page="createPPTPageTask"
        onVideoChange={generateVideoTask}
      />
      <MaterialModal
        open={materialOpen}
        onClose={() => setMaterialOpen(false)}
        onOk={(data: ImageList[]) => {
          setImageList(data);
          message.success("保存成功");
        }}
        data={imageList}
      />
      <UploadText
        open={uploadTextOpen}
        onOk={(text) => {
          getUploadText(text);
        }}
        onClose={() => {
          setUploadTextOpen(false);
        }}
      />
    </>
  );
};

export default CreateImageTextVideo;
