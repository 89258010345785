import { FC } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { ButtonSelf, SelectSubtitle, textReplace } from "@/components";
import styles from "./index.module.scss";
import useAction from "./hooks";
import TimeCard from "../time-card";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";
import { EXPORT_SUBTITLE_OPTIONS } from "@/lib/constants";
import { Flex, Button, Select, theme } from "antd";

const TranslateSubtitle: FC = () => {
  const {
    myTheme,
    loadingGenerate,
    subtitleList,
    selectValue,
    subtitle,
    videoTranslateData,
    open,
    updateDubbingDataLoading,
    selectLanguage,
    getSubtitleFileSrcLoading,
    setOpen,
    generateVideo,
    audioListChange,
    tanslateSubtitleChange,
    generateCheck,
    getVideoData,
    getDubbingData,
    updateCurrentDubbingData,
    setSelectLanguage,
    getSubtitleFileSrc,
  } = useAction();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <div className={styles["content"]}>
        {/* 头部 */}
        <div className={styles["header"]}>
          <Link
            className={styles["button-back"]}
            to={"/videoTranslate/createSelectTask"}
          >
            <ArrowLeftOutlined
              className={`${
                myTheme
                  ? styles["text-color-light"]
                  : styles["text-color-night"]
              }`}
            />
          </Link>
          <div className={styles["button"]}>
            <SelectSubtitle
              onChange={tanslateSubtitleChange}
              value={selectValue}
              subtitle={subtitle}
            />
            <ButtonSelf
              flag={true}
              loading={updateDubbingDataLoading}
              onClick={() => updateCurrentDubbingData()}
            >
              保存
            </ButtonSelf>
            <Flex
              style={{
                backgroundColor: colorBgContainer,
                borderRadius: 6,
              }}
              className={
                myTheme
                  ? styles["down-subtitle-light"]
                  : styles["down-subtitle-black"]
              }
            >
              <Button
                loading={getSubtitleFileSrcLoading}
                icon={<DownloadOutlined />}
                style={{
                  border: 0,
                  borderRadius: "6px 0 0 6px",
                }}
                className={
                  myTheme
                    ? styles["down-subtitle-btn-light"]
                    : styles["down-subtitle-btn-black"]
                }
                onClick={() =>
                  getSubtitleFileSrc({
                    subtitles: subtitleList.map((item) =>
                      textReplace(item.new.text)
                    ),
                    language: selectLanguage,
                  })
                }
              >
                字幕
              </Button>
              <Select
                bordered={false}
                style={{
                  borderRadius: "0 6px 6px 0",
                  backgroundColor: colorBgContainer,
                  width: 100,
                }}
                value={selectLanguage}
                options={EXPORT_SUBTITLE_OPTIONS}
                onChange={(value) => {
                  setSelectLanguage(value);
                }}
              />
            </Flex>
            <ButtonSelf
              btnName="生成"
              flag={generateCheck()}
              loading={loadingGenerate}
              onClick={generateVideo}
            />
          </div>
        </div>
        {/* 时间卡片 */}
        <TimeCard
          videoName={videoTranslateData.name}
          audioList={subtitleList}
          audioListChange={audioListChange}
        />
      </div>
      <SiderButton onClick={() => setOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        page="videoTranslateTask"
        defaultValue="video"
        onVideoChange={getVideoData}
        onTranslateChange={getDubbingData}
      />
    </>
  );
};

export default TranslateSubtitle;
