import { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Select, theme, Upload } from "antd";
import {
  ArrowLeftOutlined,
  InboxOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { ButtonSelf, textReplace } from "@/components";
import styles from "./index.module.scss";
import useAction from "./hooks";
import TimeCard from "../time-card";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";
import { EXPORT_SUBTITLE_OPTIONS } from "@/lib/constants";

const { Dragger } = Upload;

const VideoPreview: FC = () => {
  const {
    myTheme,
    loadingGenerate,
    uploadProps,
    audioList,
    open,
    videoDubbingAudioData,
    updateDubbingDataLoading,
    selectLanguage,
    getSubtitleFileSrcLoading,
    generateVideo,
    audioListChange,
    generateCheck,
    setOpen,
    getDubbingData,
    getVideoData,
    updateCurrentDubbingData,
    setSelectLanguage,
    getSubtitleFileSrc,
  } = useAction();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <div className={styles["content"]}>
        {/* 头部 */}
        <div className={styles["header"]}>
          <Link
            className={styles["button-back"]}
            to={"/CreateVideoDubbingRoute/videoDubbing"}
          >
            <ArrowLeftOutlined
              className={`${
                myTheme
                  ? styles["text-color-light"]
                  : styles["text-color-night"]
              }`}
            />
          </Link>
          <Flex gap={10}>
            <ButtonSelf
              flag={true}
              loading={updateDubbingDataLoading}
              onClick={() => updateCurrentDubbingData()}
            >
              保存
            </ButtonSelf>
            <Flex
              style={{
                backgroundColor: colorBgContainer,
                borderRadius: 6,
              }}
              className={
                myTheme
                  ? styles["down-subtitle-light"]
                  : styles["down-subtitle-black"]
              }
            >
              <Button
                loading={getSubtitleFileSrcLoading}
                icon={<DownloadOutlined />}
                style={{
                  border: 0,
                  borderRadius: "6px 0 0 6px",
                }}
                className={
                  myTheme
                    ? styles["down-subtitle-btn-light"]
                    : styles["down-subtitle-btn-black"]
                }
                onClick={() =>
                  getSubtitleFileSrc({
                    subtitles: audioList.map((item) => textReplace(item.text)),
                    language: selectLanguage,
                  })
                }
              >
                字幕
              </Button>
              <Select
                bordered={false}
                style={{
                  borderRadius: "0 6px 6px 0",
                  backgroundColor: colorBgContainer,
                  width: 100,
                }}
                value={selectLanguage}
                options={EXPORT_SUBTITLE_OPTIONS}
                onChange={(value) => {
                  setSelectLanguage(value);
                }}
              />
            </Flex>
          </Flex>
        </div>

        {/* 中间 */}
        <div className={styles["time-card"]}>
          {/* 时间卡片 */}
          <TimeCard
            videoName={videoDubbingAudioData.name}
            audioList={audioList}
            audioListChange={audioListChange}
          />
          {/* 右侧边栏 */}
          <div className={styles["side"]}>
            <div className={styles["file"]}>
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined
                    className={`${!myTheme && styles["text-color-night"]}`}
                  />
                </p>
                <p className={styles["upload-text"]}>上传视频（.mp4/.wav）</p>
                <p className={styles["upload-hint"]}>点击或直接拖放文件</p>
              </Dragger>
            </div>
            <div className={styles["footer"]}>
              <ButtonSelf
                btnName="生成视频"
                flag={generateCheck()}
                loading={loadingGenerate}
                onClick={generateVideo}
              />
            </div>
          </div>
        </div>
      </div>
      <SiderButton onClick={() => setOpen(true)} position={{ top: 20 }} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        defaultValue="video"
        page="videoDubbingAudioTask"
        onVideoChange={getVideoData}
        onAudioChange={getDubbingData}
      />
    </>
  );
};

export default VideoPreview;
