import api from "./api/api";
import {
  AudioGeneration,
  PostDownLoadSubtitleApiProps,
  PostDownLoadSubtitleApiRespProps,
  VideoDubbingGeneration,
  VideoTaskMessageProps,
} from "./api.types";

export const postAudioApi = async (params: AudioGeneration) => {
  return await api.post<VideoTaskMessageProps>("/api/video/tts", params);
};

export const postVideoDubbingApi = async (params: VideoDubbingGeneration) => {
  return await api.post("/api/video/dubbing", params);
};

export const postDownLoadSubtitleApi = async (
  data: PostDownLoadSubtitleApiProps
) => {
  return await api.post<PostDownLoadSubtitleApiRespProps>(
    "/video/subtitles",
    data
  );
};
