import ReactQuill from "react-quill";
import { PositionProp } from "./props";
import { checkText } from "../checkText";

const REGEXP_LIST = [
  /<span style="color: rgb\(156, 220, 254\);">/g,
  /<u style="color: rgb\(0, 177, 74\);">/g,
  /<u style="color: rgb\(156, 220, 254\);">/g,
  // 人声的值在100开头时的数字拼读
  /<u style="color: rgb\(156, 220, 255\);">/g,
  /<\/span>|<\/u>/g,
  /<br>/g,
  /&nbsp;/g,
  /&amp;/g,
];

const REGEXP_LIST_REPLACE = [
  '<say-as interpret-as="characters">',
  '<say-as interpret-as="cardinal">',
  '<say-as interpret-as="digits">',
  // 人声的值在100开头时的数字拼读
  '<say-as interpret-as="number_digit">',
  "</say-as>",
  "\n",
  " ",
  "&",
];

// 选中字体变色
export const changeColor = (
  color: string,
  underline: boolean,
  position: PositionProp | undefined,
  refs: React.RefObject<ReactQuill>
) => {
  let input = refs.current;
  if (input && position) {
    let ops = [
      { retain: position?.start },
      { delete: position?.length },
      {
        insert: position?.text,
        attributes: {
          color: color,
          underline: underline,
        },
      },
    ];
    // 当选中开头时不能添加retain
    if (position.start === 0) {
      ops.shift();
    }
    input.editor?.updateContents({
      ...input.editor.getContents(),
      ops,
    });
  }
};

// 文本处理
export const textReplace = (text: string) => {
  if (text) {
    const regP = /<p>|<\/p>/g;
    const arr = text.split(regP).filter((item) => item.length !== 0);
    const newArr = arr.map((item) => {
      let str = item;

      REGEXP_LIST.forEach((citem, cindex) => {
        str = str.replace(citem, REGEXP_LIST_REPLACE[cindex]);
      });

      return str;
    });
    return newArr.join("\n");
  }
  return text;
};

// 文本检测（自动换行）
export const richTextCheck = (
  ref: React.RefObject<ReactQuill>,
  originText?: string
) => {
  if (ref) {
    let input = ref.current;
    if (input) {
      const newText = checkText(originText ?? input.getEditor().getText());
      const reg = /\n/g;
      while (reg.test(newText)) {
        const text = input.getEditor().getText();
        if (
          text[reg.lastIndex - 1] !== "\n" &&
          reg.lastIndex !== newText.length
        ) {
          input.getEditor().insertText(reg.lastIndex - 1, "\n");
        }
      }
    }
    const value = input?.value;
    return value ? value.toString() : "";
  }
};
