import { useEffect, useRef, useState } from "react";
import { useRequest, useUnmount } from "ahooks";
import ReactQuill from "react-quill";
import { postImageGeneration2Api } from "@/services/image-video";
import { message } from "antd";
import { useAigcVideoStore, useVideoStore } from "@/store";
import {
  changeColor,
  checkText,
  subtitleLen,
  textIsFail,
  textReplace,
} from "@/components";
import { ImageList, TextSelected, subtitleProp } from "./props";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";

const useAction = () => {
  // 存储视频,主题颜色
  const [setImageVideoList] = useAigcVideoStore((state) => [
    state.setImageVideoList,
  ]);

  // 存储视频进度列表
  const [createPPTPageTask, setCreatePPTPageTask] = useVideoGenerateStateStore(
    (state) => [state.createPPTPageTask, state.setCreatePPTPageTask]
  );

  // ppt图片
  const [createPPTPageList, settingStyleData, setCreatePPTPageList] =
    useVideoStore((state) => [
      state.createPPTPageList,
      state.settingStyleData,
      state.setCreatePPTPageList,
    ]);

  const inputRef = useRef<ReactQuill>(null);
  const [textSelected, textSelectedAction] = useState<TextSelected>();
  const [inputText, inputTextAction] = useState<string>(createPPTPageList.text);
  const [videoName, videoNameAction] = useState<string>(
    createPPTPageList.title
  );

  const [imageList, setImageList] = useState<ImageList[]>(
    () => createPPTPageList.imageList
  );
  const [selectKey, selectKeyAction] = useState<string>(
    createPPTPageList.selectKey
  );
  const [uploadFile, uploadFileAction] = useState<any[]>(
    createPPTPageList.uploadFile
  );

  const [languageNum, languageNumAction] = useState<number[] | undefined>(
    settingStyleData.createPPTTask.ppt_voice
  );
  const [loading, setLoading] = useState(false);
  const [bgmNum, bgmNumAction] = useState<number>(
    settingStyleData.createPPTTask.ppt_bgc_music
  );
  const [subtitle, subtitleAction] = useState<subtitleProp>({
    main: settingStyleData.createPPTTask.ppt_subtitle[0],
    second: settingStyleData.createPPTTask.ppt_subtitle[1],
  });
  const [subtitleSelectValue, subtitleSelectValueAction] = useState<number[]>(
    settingStyleData.createPPTTask.ppt_subtitle
  );
  const [speechRate, speechRateAction] = useState<number>(
    settingStyleData.createPPTTask.ppt_speaking_speed
  );
  const [textPassFlag, textPassFlagAction] = useState<boolean>(true);
  const [textMarkShow, textMarkShowAction] = useState(false);
  const [voiceNum, voiceNumAction] = useState<Number>(0);
  const [tasksOpen, setTasksOpen] = useState(false);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [selectBorderValue, setSelectBorderValue] = useState(0);

  const imageTextSubtitleChange = (value: number[]) => {
    subtitleSelectValueAction(value);

    if (value[0] === 0) {
      subtitleAction({ main: value[0], second: 0 });
    } else if (value[0] >= subtitleLen) {
      subtitleSelectValueAction(() => []);
      subtitleAction({
        main: undefined,
        second: undefined,
      });
    } else {
      subtitleAction({
        main: value[0],
        second: value[1],
      });
    }
  };

  const onChangeSpeakingSpeed = (value: number) => {
    speechRateAction(value);
  };

  // 更改语音
  const onChange = (valueList?: number[]) => {
    languageNumAction(valueList);

    if (valueList) {
      voiceNumAction(() => Number(valueList[1]));
    }

    if (valueList && valueList[0] === 0) {
      textMarkShowAction(() => true);
    } else {
      textMarkShowAction(() => false);
      const text = inputRef.current?.getEditor().getText();
      if (text) {
        changeColor(
          "",
          false,
          { text: text, start: 0, length: text?.length },
          inputRef
        );
      }
    }
  };

  const onChangeBgm = (value: number) => {
    bgmNumAction(value);
  };

  // 选中图片
  const pptClick = (
    e: React.MouseEvent,
    text: string | undefined,
    url: string,
    flag: boolean | undefined
  ) => {
    e.currentTarget.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
    if (url !== selectKey) {
      textPassFlagAction(() => Boolean(flag));
      selectKeyAction(() => url);
      // 会触发onChange
      Promise.resolve().then(() => {
        inputTextAction(() => (typeof text !== "undefined" ? text : ""));
      });
    }
  };
  // 普通文本更改
  const onChangeText = (text: string) => {
    let newText = text;
    const list = imageList.map((item) => {
      let textIsPass = !textIsFail(text);
      if (!textIsPass) {
        newText = checkText(text);
      }
      if (item.image.url === selectKey) {
        textPassFlagAction(() => textIsPass);
        return { ...item, text: newText, flag: textIsPass };
      }
      return item;
    });
    setImageList(() => list);
    inputTextAction(newText);
  };

  // // 富文本文本更改
  // const onChangeRichText = (value: string, text: string) => {
  //   const list = imageList.map((item) => {
  //     let textPassState = !textIsFail(text);
  //     if (item.image.url === selectKey) {
  //       textPassFlagAction(() => textPassState);
  //       return { ...item, text: value, flag: textPassState };
  //     }
  //     return item;
  //   });
  //   setImageList(() => list);
  //   inputTextAction(value);
  // };

  // // 富文本文本处理, 自动换行;
  // const formatText = (orifinText: string) => {
  //   if (timer) {
  //     clearTimeout(timer);
  //     timer = null;
  //   }
  //   timer = setTimeout(() => {
  //     if (inputRef.current) {
  //       const value = richTextCheck(inputRef, orifinText);
  //       onChangeRichText(
  //         value ? value : "",
  //         inputRef.current.getEditor().getText()
  //       );
  //     }
  //   }, 1500);
  // };

  // 删除按钮
  const pptDel = (url: string) => {
    return () => {
      const tempList = imageList.filter((item) => {
        return item.image.url !== url;
      });
      if (selectKey === url) {
        selectKeyAction("");
        inputTextAction("");
      }
      setImageList([...tempList]);
    };
  };

  // 检查配置项
  const generateCheck = () => {
    if (
      imageList.length !== 0 &&
      subtitleSelectValue.length !== 0 &&
      typeof speechRate !== "undefined" &&
      typeof bgmNum !== "undefined" &&
      typeof languageNum !== "undefined"
    ) {
      return true;
    }

    return false;
  };

  const postImageGeneration2 = useRequest(postImageGeneration2Api, {
    manual: true,
    onSuccess(result) {
      setLoading(false);
      if (result.data.task_id) {
        message.success("success");
        setCreatePPTPageTask({
          ...createPPTPageTask,
          video: {
            tasks: [
              ...createPPTPageTask.video.tasks,
              {
                id: result.data.task_id,
                name: videoName,
                isGenerate: false,
                state: 0,
                time: result.data.task_time,
              },
            ],
          },
        });
      }
    },
    onError(e) {
      setLoading(false);
      message.error("Internal Server Error");
    },
  });

  // 生成PPT视频
  const generateVideo = async () => {
    try {
      setLoading(true);
      let hasTextLenOver = false;
      imageList.forEach((item) => {
        if (item.flag === false) {
          hasTextLenOver = true;
        }
      });
      if (hasTextLenOver) {
        setLoading(false);
        message.warning("存在文本字数超出，请修改！");
        return;
      }

      const params = [];
      let textInput: string | undefined;
      for (let index = 0; index < imageList.length; index++) {
        // 检查PPT是否至少有一张有文本
        if (!textInput) {
          textInput = imageList[index].text;
        }

        params.push({
          text: textReplace(imageList[index].text || ""),
          image: imageList[index].image,
          voice: Number(languageNum?.[languageNum?.length - 1]),
        });
      }

      // PPT没有输入文本
      if (!textInput) {
        setLoading(false);
        message.warning("至少有一张PPT有文本！");
        return;
      }

      postImageGeneration2.run({
        source: params,
        bgm: bgmNum as number,
        subtitles: {
          main: typeof subtitle.main !== "undefined" ? subtitle.main : 0,
          second:
            typeof subtitle.second !== "undefined" && subtitle.second !== 0
              ? subtitle.second - subtitleLen
              : 0,
        },
        speech_rate: typeof speechRate !== "undefined" ? speechRate : 0,
        border: selectBorderValue,
      });
    } catch (error) {
      setLoading(false);
      message.error(`${error}`);
    }
  };

  //视频任务进度
  // 视频生成状态
  const generateVideoTask = (url: string, name: string) => {
    setImageVideoList(url, name);
  };

  //视频任务进度endding

  // 获取上传的文本
  const getUploadText = (textList: { [key: number]: string }) => {
    setImageList(
      imageList.map((item, index) => {
        let text = "";
        if (textList[index + 1]) {
          text = checkText(textList[index + 1]);
        }
        if (item.image.url === selectKey) {
          inputTextAction(text);
        }
        return { ...item, text };
      })
    );
  };

  useUnmount(() => [
    setCreatePPTPageList({
      ...createPPTPageList,
      title: videoName,
      text: inputText,
      selectKey: selectKey,
      uploadFile: uploadFile,
      imageList: imageList,
    }),
  ]);

  useEffect(() => {
    languageNumAction(settingStyleData.createPPTTask.ppt_voice);
    bgmNumAction(settingStyleData.createPPTTask.ppt_bgc_music);
    subtitleAction({
      main: settingStyleData.createPPTTask.ppt_subtitle[0],
      second: settingStyleData.createPPTTask.ppt_subtitle[1],
    });
    subtitleSelectValueAction(settingStyleData.createPPTTask.ppt_subtitle);
    speechRateAction(settingStyleData.createPPTTask.ppt_speaking_speed);
  }, [settingStyleData.createPPTTask]);

  return {
    inputRef,
    textSelected,
    inputText,
    imageList,
    loading,
    videoName,
    selectKey,
    uploadFile,
    subtitleSelectValue,
    subtitle,
    textPassFlag,
    textMarkShow,
    voiceNum,
    tasksOpen,
    materialOpen,
    languageNum,
    bgmNum,
    speechRate,
    selectBorderValue,
    textSelectedAction,
    onChangeBgm,
    selectKeyAction,
    inputTextAction,
    videoNameAction,
    onChangeText,
    setImageList,
    onChange,
    generateVideo,
    pptDel,
    pptClick,
    imageTextSubtitleChange,
    onChangeSpeakingSpeed,
    uploadFileAction,
    generateCheck,
    setTasksOpen,
    generateVideoTask,
    setMaterialOpen,
    setSelectBorderValue,
    getUploadText,
  };
};

export default useAction;
